// DataContext.js
import React, { createContext, useContext, useReducer } from 'react';

const DataContext = createContext();

const dataReducer = (state, action) => {
    switch (action.type) {
        case 'SET_DOCTOR_DATA':
            return { ...state, doctorData: action.payload };
        case 'SET_PATIENT_NAME':
            return { ...state, patientName: action.payload };

        case 'SET_TIMESLOT_DATA':
            return { ...state, timeslotData: action.payload };
        default:
            return state;
    }
};

export const DataProvider = ({ children }) => {
    const [state, dispatch] = useReducer(dataReducer, {
        doctorData: {},
        timeslotData: {},
        patientName: '',
    });

    return (
        <DataContext.Provider value={{ state, dispatch }}>
            {children}
        </DataContext.Provider>
    );
};

export const useData = () => {
    const context = useContext(DataContext);
    if (!context) {
        throw new Error('useData must be used within a DataProvider');
    }
    return context;
};