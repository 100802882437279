import React, { useState } from 'react'
import { BiListCheck } from 'react-icons/bi';
import { IoCall } from 'react-icons/io5';

const ContactUsButton = () => {
    const [isHovered, setHovered] = useState(false);

    const handleMouseEnter = () => {
        setHovered(true);
    };

    const handleMouseLeave = () => {
        setHovered(false);
    };
    return (
        <div>
            <div
                className={`rounded ${isHovered ? 'rounded-5' : 'rounded-circle'} shadow d-flex text-center justify-content-${isHovered ? 'between' : 'center'} align-items-center p-${isHovered ? '2' : '0'}`}
                style={{
                    width: isHovered ? "200px" : "50px",
                    cursor: "pointer",
                    height: "50px",
                    background: "darkblue",
                    position: "fixed",
                    top: "50%",
                    right: "0px",
                    zIndex: 999,
                    transition: "width 0.3s ease, height 0.3s ease, border-radius 0.3s ease"
                }}
                onClick={() => {
                    var phoneNumber = 'tel:7022209038';
                    window.location.href = phoneNumber;
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {isHovered ? (<>
                    <div>
                        <IoCall className='text-white' />
                    </div>
                    <div className='text-white'>
                        Call Us : 7022209038
                    </div>
                </>) : (<>
                    <div>
                        <IoCall className='text-white' />
                    </div>
                </>)}
            </div>
        </div>
    )
}

export default ContactUsButton;
