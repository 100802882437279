import React, { createContext, useContext, useEffect, useState } from 'react';

const PharmacyCartContext = createContext();

const PharmacyCartProvider = ({ children }) => {
    const [PharmacyCartItems, setPharmacyCartItems] = useState([]);
    const [cartMessage, setCartMessage] = useState(null);
    const [PharmacyTotalAmount, setPharmacyTotalAmount] = useState(0);
    const [counters, setCounters] = useState({});

    useEffect(() => {
        setPharmacyTotalAmount(PharmacyCartItems.reduce(
            (acc, item) => acc + item.selling_price * (counters[item._id] || 1),
            0
        ))
    }, [PharmacyCartItems, counters]);

    const handleIncrement = (itemId) => {
        setCounters((prevCounters) => ({
            ...prevCounters,
            [itemId]: (prevCounters[itemId] || 0) + 1,
        }));
    }

    const handleDecrement = (itemId) => {
        if (counters[itemId] > 1) {
            setCounters((prevCounters) => ({
                ...prevCounters,
                [itemId]: prevCounters[itemId] - 1,
            }));
        }
    };

    const addToPharmacyCart = (item) => {
        setPharmacyCartItems((prevCartItems) => [...prevCartItems, item]);
        setCartMessage(`Item "${item.med_name}" added to cart`);
        setTimeout(() => setCartMessage(null), 3000);
    };

    const removeAllItemsFromCart = () => {
        setPharmacyCartItems([]);
    };

    const removeFromCart = (item) => {

        setPharmacyCartItems((prevCartItems) => prevCartItems.filter((cartItem) => cartItem !== item));
        setCartMessage(`Item "${item.med_name}" removed from cart`);
        setTimeout(() => setCartMessage(null), 3000);
    };
    const isInPharmacyCart = (item) => {
        return PharmacyCartItems.some((cartItem) => cartItem._id === item._id);
    };

    return (
        <PharmacyCartContext.Provider value={{ PharmacyCartItems, addToPharmacyCart, removeFromCart, cartMessage, isInPharmacyCart, handleDecrement, handleIncrement, counters, PharmacyTotalAmount, removeAllItemsFromCart }}>
            {children}
        </PharmacyCartContext.Provider>
    );
};

const usePharmacyCart = () => {
    const context = useContext(PharmacyCartContext);
    if (!context) {
        throw new Error('usePharmacyCart must be used within a PharmacyCartProvider');
    }
    return context;
};

export { PharmacyCartProvider, usePharmacyCart };