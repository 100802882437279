import React, { createContext, useContext, useEffect, useState } from 'react';

const LabCartContext = createContext();

const LabCartProvider = ({ children }) => {
    const [labCartItems, setLabCartItems] = useState([]);
    const [cartMessage, setCartMessage] = useState(null);
    const [LabTotalAmount, setLabTotalAmount] = useState(0);

    useEffect(() => {
        setLabTotalAmount(labCartItems.reduce(
            (acc, item) => acc + item.total_price, 0
        ))
    }, [labCartItems]);

    const addToLabCart = (item) => {
        setLabCartItems((prevCartItems) => [...prevCartItems, item]);
        setCartMessage(`Item "${item.test_name}" added to cart`);
        setTimeout(() => setCartMessage(null), 3000);
    };

    const removeAllItemsFromLabTestCart = () => {
        setLabCartItems([]);
    };
    const removeFromLabCart = (item) => {
        setLabCartItems((prevCartItems) => prevCartItems.filter((cartItem) => cartItem !== item));
        setCartMessage(`Item "${item.test_name}" removed from cart`);
        setTimeout(() => setCartMessage(null), 3000);
    };

    const isInLabCart = (item) => {
        return labCartItems.some((cartItem) => cartItem._id === item._id);
    };

    return (
        <LabCartContext.Provider value={{ labCartItems, addToLabCart, removeFromLabCart, cartMessage, isInLabCart, LabTotalAmount, removeAllItemsFromLabTestCart }}>
            {children}
        </LabCartContext.Provider>
    );
};

const useLabCart = () => {
    const context = useContext(LabCartContext);
    if (!context) {
        throw new Error('useLabCart must be used within a LabCartProvider');
    }
    return context;
};

export { LabCartProvider, useLabCart };