// LabContext.js
import React, { createContext, useContext, useState } from 'react';

const LabContext = createContext();

const LabProvider = ({ children, itemName }) => {
    const [labQuantity, setLabQuantity] = useState(0);

    const increaseLabQuantity = () => {
        setLabQuantity((prevQuantity) => prevQuantity + 1);
    };

    const decreaseLabQuantity = () => {
        setLabQuantity((prevQuantity) => (prevQuantity > 0 ? prevQuantity - 1 : 0));
    };

    const contextValue = {
        labQuantity,
        increaseLabQuantity,
        decreaseLabQuantity,
    };

    return (
        <LabContext.Provider value={contextValue}>
            {children}
        </LabContext.Provider>
    );
};

const useLab = () => {
    const context = useContext(LabContext);
    if (!context) {
        throw new Error('useLab must be used within a LabProvider');
    }
    return context;
};

export { LabProvider, useLab };