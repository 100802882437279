import React, { createContext, useContext } from 'react';

const GeocodingContext = createContext();

export const useGeocoding = () => {
    return useContext(GeocodingContext);
};

export const GeocodingProvider = ({ children }) => {
    const contextValue = useGeocodingProvider();

    return (
        <GeocodingContext.Provider value={contextValue}>
            {children}
        </GeocodingContext.Provider>
    );
};

const useGeocodingProvider = () => {
    const [shippingAddress, setShippingAddress] = React.useState({
        lat: 51.505,
        lng: -0.09,
        address: '',
    });

    return {
        shippingAddress,
        setShippingAddress,
    };
};

export default GeocodingProvider;