import React, { createContext, useContext, useState } from 'react';

const PharmacyContext = createContext();

const PharmacyProvider = ({ children, itemName }) => {
    const [pharmacyQuantity, setPharmacyQuantity] = useState(0);

    const increasePharmacyQuantity = () => {
        setPharmacyQuantity((prevQuantity) => prevQuantity + 1);
    };

    const decreasePharmacyQuantity = () => {
        setPharmacyQuantity((prevQuantity) => (prevQuantity > 0 ? prevQuantity - 1 : 0));
    };

    const contextValue = {
        pharmacyQuantity,
        increasePharmacyQuantity,
        decreasePharmacyQuantity,
    };

    return (
        <PharmacyContext.Provider value={contextValue}>
            {children}
        </PharmacyContext.Provider>
    );
};

const usePharmacy = () => {
    const context = useContext(PharmacyContext);
    if (!context) {
        throw new Error('usePharmacy must be used within a PharmacyProvider');
    }
    return context;
};

export { PharmacyProvider, usePharmacy };
