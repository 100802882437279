import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "./index.css";
import { PharmacyProvider } from './State/QuantityContext';
import { LabProvider } from './State/LabQuanity';
import { GeocodingProvider } from './State/GeoCoding.jsx';
import { DataProvider } from './State/DataContext.jsx';
import { PharmacyCartProvider } from './State/PharmacyCartContext';
import { LabCartProvider } from './State/LabCartContext';
import { LabPackageCartProvider } from './State/LabPackageCartContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <LabPackageCartProvider>
    <LabCartProvider>
      <PharmacyCartProvider>
        <LabProvider itemName="Lab">
          <PharmacyProvider itemName="Pharmacy">
            <GeocodingProvider>
              <DataProvider>
                <App />
              </DataProvider>
            </GeocodingProvider>
          </PharmacyProvider>
        </LabProvider>
      </PharmacyCartProvider>
    </LabCartProvider>
  </LabPackageCartProvider>
);