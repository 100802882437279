import React, { createContext, useContext, useEffect, useState } from 'react';

const LabPackageCartContext = createContext();

const LabPackageCartProvider = ({ children }) => {
    const [labPackageCartItems, setLabPackageCartItems] = useState([]);
    const [cartMessage, setCartMessage] = useState(null);
    const [LabPackageTotalAmount, setLabPackageTotalAmount] = useState(0);
    useEffect(() => {
        setLabPackageTotalAmount(labPackageCartItems.reduce(
            (acc, item) => acc + item.health_package_price, 0
        ))
    }, [labPackageCartItems]);

    const addToLabPackageCart = (item) => {
        setLabPackageCartItems((prevCartItems) => [...prevCartItems, item]);
        setCartMessage(`Item "${item.health_package_name}" added to cart`);
        setTimeout(() => setCartMessage(null), 3000);
    };

    const removeFromLabPackageCart = (item) => {
        setLabPackageCartItems((prevCartItems) => prevCartItems.filter((cartItem) => cartItem !== item));
        setCartMessage(`Item "${item.health_package_name}" removed from cart`);
        setTimeout(() => setCartMessage(null), 3000);
    };


    const removeAllItemsFromLabPackageCart = () => {
        setLabPackageCartItems([]);
    };

    const isInLabPackageCart = (item) => {
        return labPackageCartItems.some((cartItem) => cartItem._id === item._id);
    };

    return (
        <LabPackageCartContext.Provider value={{ labPackageCartItems, addToLabPackageCart, removeFromLabPackageCart, cartMessage, isInLabPackageCart, LabPackageTotalAmount, removeAllItemsFromLabPackageCart }}>
            {children}
        </LabPackageCartContext.Provider>
    );
};

const usePackageLabCart = () => {
    const context = useContext(LabPackageCartContext);
    if (!context) {
        throw new Error('usePackageLabCart must be used within a LabPackageCartProvider');
    }
    return context;
};

export { LabPackageCartProvider, usePackageLabCart };